import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    CardActionArea,
    CircularProgress,
    Grid,
    Link,
    Paper,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import {ConfirmationNumber, EmojiEvents, OpenInNew} from "@mui/icons-material";
import BgGame from "../../assets/imgs/play/bg-game.png";
import CardBack from "../../assets/imgs/play/card-back.png";
import ScratchCard from "../ScratchCard";
import LottoABI from "../../abis/ZoorbisLotto.json";
import DialogBuyTickets from "./DialogBuyTickets";
import ButtonPlay from "./ButtonPlay";
import BgButtonPlay from "../../assets/imgs/button.png";

import CardEagle from "../../assets/imgs/play/card-aguila.png";
import CardGorilla from "../../assets/imgs/play/card-gorila.png";
import CardTiger from "../../assets/imgs/play/card-tigre.png";
import CardFox from "../../assets/imgs/play/card-zorro.png";
import CardNo from "../../assets/imgs/play/card-no.png";
import DialogWinner from "./DialogWinner";

import BannerZoorbis from "../../assets/imgs/banner_kadorvis.png";
import {useSelector} from "react-redux";
import {ethers} from "ethers";
import Swal from "sweetalert2";

declare global {
    interface Window {
        ethereum: import('ethers').providers.ExternalProvider;
    }
}

const PlayComponent = () => {

    const sm = useMediaQuery('(max-width: 899px)');
    const xs = useMediaQuery('(max-width: 599px)');

    // Redux
    // @ts-ignore
    const metamaskStore = useSelector(state => state.rootRdx.metamaskRdx);

    const refBoxAuth = useRef() as React.MutableRefObject<HTMLDivElement>;
    const refGrid = useRef() as React.MutableRefObject<HTMLElement>;

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState<any>();
    const [arrayNumbers, setArrayNumbers] = useState<number[]>([]);
    const [idTicketRedeemed, setIdTicketRedeemed] = useState(-1);

    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });

    const [ticketsOwned, setTicketsOwned] = useState(0);
    const [userPool, setUserPool] = useState(-1);

    const [msgCheckingIfTicketWon, setMsgCheckingIfTicketWon] = useState('');

    const [showDialogBuyTickets, setShowDialogBuyTickets] = useState(false);
    const [showDialogWinner, setShowDialogWinner] = useState(false);
    const [showGame, setShowGame] = useState(false);

    const [isWinner, setIsWinner] = useState(false);

    const [loadingRedeem, setLoadingRedeem] = useState(false);
    const [numberRevealed, setNumberRevealed] = useState(0);
    const [loadingCheckIfWinner, setLoadingCheckIfWinner] = useState(false);

    const getDimensions = () => {
        if (refGrid.current) {
            setDimensions({
                width: refGrid.current.offsetWidth,
                height: refGrid.current.offsetHeight
            });
        }
        // console.log(dimensions);
    }

    useEffect(() => {
        getDimensions();
    }, [isAuthenticated, showGame]);

    useEffect(() => {
        if (metamaskStore.accountsState[0] && metamaskStore.avalancheConnectedState === true) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [metamaskStore.accountsState, metamaskStore.avalancheConnectedState]);

    const getUserTickets = () => {
        const tokenAbi = LottoABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const userAddress = signer.getAddress();

        const tx = tokenContract.getTicketsAmount(userAddress, {
            gasLimit: 1000000,
        });

        tx
            .then((res: any) => {
                console.log('Tickets owned: ', parseInt(res));
                setTicketsOwned(parseInt(res.toString()));
            })
            .catch((err: any) => {
                console.log('Error getting tickets owned: ', err);
                setTicketsOwned(0);
            });
    }

    const redeemTicket = async () => {
        setArrayNumbers([]);
        setNumberRevealed(0);
        setIdTicketRedeemed(-1);

        const tokenAbi = LottoABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const tx = await tokenContract.redeemTicket({
            gasLimit: 1000000,
        });

        new Promise((resolve, reject) => {
            tx.wait()
                .then((answer: any) => {
                    if (answer.status === 1) {
                        const numberArrayTmp = answer?.events[0]?.args[2];
                        const numberArray = numberArrayTmp.map((n: any) => n.toNumber());
                        console.log('Number array: ', numberArray);

                        const idTicketTmp = answer?.events[0]?.args[1];
                        const idTicket = idTicketTmp.toNumber();
                        console.log('Id ticket: ', idTicket);

                        if (numberArray.length > 0) {
                            setArrayNumbers(numberArray);
                            setIdTicketRedeemed(idTicket);
                            setShowGame(true);
                        }

                    } else {
                        setArrayNumbers([]);
                        setShowGame(false);

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            confirmButtonText: 'Retry'
                        });
                    }

                    resolve(answer);
                })
                .catch((err: any) => {
                    console.log('Error redeeming ticket: ', err);

                    setArrayNumbers([]);
                    setShowGame(false);
                    setIdTicketRedeemed(-1);

                    if (err?.data?.message) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.data?.message,
                            confirmButtonText: 'Retry'
                        });
                    }

                    reject(err);
                })
                .finally(() => {
                    setLoadingRedeem(false);
                    getUserTickets();
                });
        });
    }

    const resetGame = () => {
        setArrayNumbers([]);
        setShowGame(false);
        setNumberRevealed(0);
        setIdTicketRedeemed(-1);
        setLoadingCheckIfWinner(false);

        getUserTickets();

        // Reload page
        window.location.reload();
    }

    useEffect(() => {
        if (isAuthenticated) {
            getUserTickets();
        }
    }, [isAuthenticated, showGame, showDialogBuyTickets]);

    const ticketIsWinner = async (id: number) => {
        setMsgCheckingIfTicketWon('Checking if ticket is winner...');

        const tokenAbi = LottoABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const currentUser = signer.getAddress();

        return new Promise(async (resolve, reject) => {
            try {
                const tx = await tokenContract.checkIfWinner(currentUser, id);
                const answer = await tx.wait();
                console.log('Ticket is winner: ', answer);

                resolve(answer);
            } catch (e: any) {
                console.log('Error checking if ticket is winner: ', e);
                reject(e);
            }
        });
    }

    const getUserPool = async () => {
        const tokenAbi = LottoABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const currentUser = signer.getAddress();

        try {
            return await tokenContract.getTotalPrizeByAddress(currentUser, {
                gasLimit: 1000000,
            });
        } catch (e) {
            console.log('Error getting user pool: ', e);
            return 0;
        }
    }

    const claimPrize = async () => {
        const tokenAbi = LottoABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        return new Promise(async (resolve, reject) => {
            try {
                const tx = await tokenContract.claimPrizes();
                const answer = await tx.wait();
                console.log('Prize claimed: ', answer);
                resolve(answer);
            } catch (e: any) {
                console.log('Error claiming prize: ', e);
                reject(e);
            }
        });
    }

    useEffect(() => {
        if (isAuthenticated) {
            getUserPool().then((res: any) => {
                console.log('User Pool: ', parseInt(res.toString()));
                setUserPool(parseInt(res.toString()));
            });
        }
    }, [isAuthenticated, showGame, showDialogBuyTickets]);

    useEffect(() => {
        if (numberRevealed === 8) {
            if (idTicketRedeemed !== -1) {
                setLoadingCheckIfWinner(true);

                ticketIsWinner(idTicketRedeemed).then((res: any) => {
                    console.log('Ticket is winner: ', res);

                    if (res?.status === 1) {
                        if (res?.events[0]?.args[0]) {
                            setMsgCheckingIfTicketWon('Ticket is winner!');
                            setIsWinner(true);
                            setShowDialogWinner(true);
                        } else {
                            setMsgCheckingIfTicketWon('Ticket is not winner!');
                            setIsWinner(false);
                            setShowDialogWinner(true);
                        }
                    } else {
                        setMsgCheckingIfTicketWon('Error checking if ticket is winner!');
                        setIsWinner(false);
                        setShowDialogWinner(true);
                    }
                });
            }
        }
    }, [numberRevealed]);

    const returnCardItem = (numberCard: number) => {
        if (numberCard >= 1 && numberCard <= 4) {
            const sourceImage = numberCard === 1 ? CardEagle : numberCard === 2 ? CardGorilla : numberCard === 3 ? CardTiger : CardFox;
            return <img
                src={sourceImage}
                alt={"Card Front"}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill',
                }}
            />
        } else {
            return <img
                src={CardNo}
                alt={"Card No"}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill',
                }}
            />
        }
    }

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 'calc(100% - 40px)',
                padding: '20px',
            }}
        >
            {
                isAuthenticated ?
                    <Box
                        ref={refBoxAuth}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 'calc(100% - 40px)',
                            padding: '20px',
                        }}
                    >
                        <DialogBuyTickets
                            showDialog={showDialogBuyTickets}
                            setShowDialog={setShowDialogBuyTickets}
                            sm={sm}
                            isAuth={isAuthenticated}
                            currentUser={currentUser}
                            getUserTickets={getUserTickets}
                        />

                        <DialogWinner
                            isWinner={isWinner}
                            setShowDialog={setShowDialogWinner}
                            showDialog={showDialogWinner}
                            sm={sm}
                        />

                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 'calc(100% - 20px)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: '20px',
                                }}
                            >
                                <h1
                                    style={{
                                        color: '#FFFFFFDD',
                                        fontWeight: '800',
                                        letterSpacing: '1px',
                                        fontSize: '40px',
                                        margin: '0px',
                                    }}
                                >
                                    Play
                                </h1>
                                <Link
                                    href="https://docs.zoorbis.com/"
                                    target="_blank"
                                    style={{
                                        marginLeft: '10px',
                                        marginTop: '17px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    sx={{
                                        color: '#F8C600 !important',
                                        '&:hover': {
                                            textDecoration: 'none',
                                            color: '#FFFFFFCC !important',
                                        }
                                    }}
                                >
                                    Docs <OpenInNew style={{fontSize: 15, marginLeft: '5px'}}/>
                                </Link>
                            </Box>

                            <CardActionArea
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    padding: '10px',
                                }}
                                onClick={() => {
                                    setShowDialogBuyTickets(true);
                                }}
                            >
                                <img
                                    src={require('../../assets/imgs/button_2.png')}
                                    alt="ZOO Token"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                    }}
                                />

                                <ConfirmationNumber
                                    style={{
                                        color: '#F0B12B',
                                        fontSize: '30px',
                                        marginRight: '10px',
                                    }}
                                />
                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#F0B12B',
                                        fontWeight: '500',
                                        letterSpacing: '1px',
                                    }}
                                >
                                    Buy <span style={{fontWeight: '800'}}>Tickets</span>
                                </Typography>
                            </CardActionArea>
                        </Box>

                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 'calc(100% - 20px)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px',
                                padding: '10px',
                            }}
                        >
                            <CardActionArea
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    padding: '5px',
                                    borderRadius: '5px',
                                }}
                            >
                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#F0B12B',
                                        fontWeight: '500',
                                        letterSpacing: '1px',
                                        marginRight: '10px',
                                    }}
                                >
                                    My tickets:
                                </Typography>

                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#FFFFFFDD',
                                        fontWeight: '800',
                                        letterSpacing: '1px',
                                        marginRight: '10px',
                                    }}
                                >
                                    {
                                        ticketsOwned
                                    }
                                </Typography>

                                <ConfirmationNumber
                                    style={{
                                        color: '#F8C600',
                                        fontSize: '30px',
                                        marginRight: '10px',
                                    }}
                                />
                            </CardActionArea>

                            <CardActionArea
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    border: '1px solid #F8C60033',
                                    marginRight: sm ? '0px' : '10px',
                                }}
                                onClick={() => {
                                    claimPrize()
                                        .then(() => {
                                            // Reload window
                                            window.location.reload();
                                        })
                                        .catch(async (err) => {
                                            console.log(err)
                                            await Swal.fire({
                                                icon: 'error',
                                                title: 'Oops...',
                                                text: err,
                                            });
                                        });
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: 'fit-content',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <EmojiEvents
                                        style={{
                                            color: '#F8C600',
                                            fontSize: '30px',
                                            marginRight: '10px',
                                        }}
                                    />

                                    <Typography
                                        variant={'subtitle1'}
                                        style={{
                                            color: '#F0B12B',
                                            fontWeight: '500',
                                            letterSpacing: '1px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        My Pool:
                                    </Typography>

                                    <Typography
                                        variant={'subtitle1'}
                                        style={{
                                            color: '#FFFFFFDD',
                                            fontWeight: '800',
                                            letterSpacing: '1px',
                                        }}
                                    >
                                        {
                                            ethers.utils.formatEther(userPool.toString()) + ' ZORBI'
                                        }
                                    </Typography>
                                </Box>

                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: 'fit-content',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        marginTop: '-5px'
                                    }}
                                >
                                    <Typography
                                        variant={'caption'}
                                        style={{
                                            color: '#4cf02b',
                                            fontWeight: '500',
                                            letterSpacing: '1px',
                                        }}
                                    >
                                        Click to claim
                                    </Typography>
                                </Box>
                            </CardActionArea>
                        </Box>

                        {
                            showGame ?
                                <Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: 'calc(100% - 20px)',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Tooltip title={'New Game'}>
                                            <Button
                                                style={{
                                                    width: '120px',
                                                    height: '40px',
                                                    border: '1px solid #FFFFFF99',
                                                    marginRight: '10px',
                                                    marginBottom: '20px',
                                                }}
                                                onClick={() => resetGame()}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    style={{
                                                        color: '#FFFFFFDD',
                                                        fontWeight: '600',
                                                        letterSpacing: '1px',
                                                    }}
                                                >
                                                    New Game
                                                </Typography>
                                            </Button>
                                        </Tooltip>
                                    </Box>

                                    {
                                        loadingCheckIfWinner &&
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: 'calc(100% - 20px)',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Typography
                                                variant={'subtitle1'}
                                                style={{
                                                    color: '#F8C600',
                                                    fontWeight: '600',
                                                    letterSpacing: '1px',
                                                    fontSize: '25px',
                                                    WebkitTextStroke: '0.5px #772784',
                                                }}
                                            >
                                                {
                                                    msgCheckingIfTicketWon
                                                }
                                            </Typography>
                                        </Box>
                                    }

                                    <Box
                                        style={{
                                            width: '100%',
                                            minHeight: '700px',
                                            backgroundImage: `url(${BgGame})`,
                                            backgroundSize: '100% 100%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            borderRadius: '10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid
                                            container
                                            style={{
                                                width: '90%',
                                                height: '94%',
                                                flex: sm ? '0.3' : '0.6',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: sm ? xs ? '160px 0' : '80px 0' : '0',
                                            }}
                                        >
                                            {
                                                arrayNumbers.map((itemValue, index) => {
                                                    return (
                                                        <Grid
                                                            key={index}
                                                            item
                                                            xs={12} sm={6} md={3}
                                                            style={{
                                                                display: 'flex',
                                                                width: '100%',
                                                                height: 'fit-content',
                                                                padding: '10px',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Box
                                                                ref={refGrid}
                                                                style={{
                                                                    width: '180px',
                                                                    height: '260px',
                                                                    borderRadius: '15px',
                                                                }}
                                                            >
                                                                <ScratchCard
                                                                    width={dimensions.width}
                                                                    height={dimensions.height}
                                                                    image={CardBack}
                                                                    brushSize={15}
                                                                    finishPercent={40}
                                                                    onComplete={() => {
                                                                        console.log('complete');
                                                                        setNumberRevealed(numberRevealed + 1);
                                                                    }}
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            width: 'calc(100% - 20px)',
                                                                            height: 'calc(100% - 20px)',
                                                                            borderRadius: '15px',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            padding: '10px',
                                                                            margin: '0',
                                                                            background: '#00000022',
                                                                            backdropFilter: 'blur(100px)',
                                                                            WebkitBackdropFilter: 'blur(100px)',
                                                                            border: '1px solid #FFFFFF77',
                                                                        }}
                                                                    >
                                                                        {
                                                                            returnCardItem(itemValue)
                                                                        }
                                                                    </Box>
                                                                </ScratchCard>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </Box>
                                :
                                <Paper
                                    elevation={5}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'calc(100% - 20px)',
                                        height: '500px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '20px',
                                        background: '#37288A',
                                        backdropFilter: 'blur(50px)',
                                        WebkitBackdropFilter: 'blur(50px)',
                                        borderRadius: '20px',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <img
                                        src={BannerZoorbis}
                                        alt="banner"
                                        style={{
                                            width: sm ? '100%' : '100%',
                                            height: sm ? '100%' : '80%',
                                            minHeight: '500px',
                                            position: 'absolute',
                                            zIndex: '0',
                                            borderRadius: '20px',
                                            objectPosition: sm ? '10% 10%' : 'center',
                                            objectFit: sm ? 'cover' : 'fill',
                                        }}
                                    />

                                    {
                                        loadingRedeem ?
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CircularProgress
                                                    style={{
                                                        color: '#F8C600',
                                                        fontSize: '30px',
                                                        marginRight: '10px',
                                                        marginBottom: '20px',
                                                    }}
                                                />
                                                <Typography
                                                    variant={'subtitle1'}
                                                    style={{
                                                        color: '#FFFFFFDD',
                                                    }}
                                                >
                                                    Redeeming your ticket...
                                                </Typography>
                                            </Box>
                                            :
                                            <ButtonPlay
                                                style={{
                                                    marginTop: sm ? '0' : '50px',
                                                    position: 'absolute',
                                                    left: sm ? 'auto' : '10%',
                                                }}
                                                onClick={async () => {
                                                    await redeemTicket();
                                                }}
                                            >
                                                <img
                                                    src={BgButtonPlay}
                                                    alt={'bg-button-play'}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'fill',
                                                        position: 'absolute',
                                                        top: '0',
                                                        left: '0',
                                                    }}
                                                />

                                                <Typography
                                                    variant={'subtitle1'}
                                                    style={{
                                                        fontFamily: '"Poppins", sans-serif',
                                                        color: '#FFFFFFDD',
                                                        fontWeight: '700',
                                                        zIndex: '1',
                                                        letterSpacing: '1px',
                                                        fontSize: '25px',
                                                    }}
                                                >
                                                    Play
                                                </Typography>
                                            </ButtonPlay>
                                    }
                                </Paper>
                        }
                    </Box>
                    :
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <h3
                            style={{
                                color: '#FFFFFFDD',
                                fontWeight: '500',
                            }}
                        >
                            Please connect your wallet to play
                        </h3>
                    </Box>
            }
        </Box>
    );
}

export default PlayComponent;
