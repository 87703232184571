import React from "react";
import {Box, Button, Dialog, IconButton, styled, Typography} from "@mui/material";
import {Close, ReportProblem, TaskAlt} from "@mui/icons-material";
import BgModal from "../../assets/imgs/play/bg-modal.png";

interface PropsBuyTickets {
    showDialog: boolean,
    setShowDialog: (value: boolean) => void,
    sm: boolean,
    isWinner: boolean,
}

const DialogCustom = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper': {
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    // backdrop
    '& .MuiBackdrop-root': {
        backdropFilter: 'blur(50px)',
        WebkitBackdropFilter: 'blur(50px)',
    }
}));

const DialogWinner: React.FC<PropsBuyTickets> = (propsBuyTickets: PropsBuyTickets) => {

    return (
        <DialogCustom
            open={propsBuyTickets.showDialog}
            onClose={() => propsBuyTickets.setShowDialog(false)}
            fullScreen={propsBuyTickets.sm}
            fullWidth
            style={{
                overflow: 'hidden',
                backgroundColor: 'transparent',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'calc(100% - 80px)',
                    height: propsBuyTickets.sm ? '100%' : '500px',
                    backgroundColor: 'transparent',
                    padding: '0',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={BgModal}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: -1,
                    }}
                    alt={'bg-modal'}
                />

                <IconButton
                    style={{
                        position: propsBuyTickets.sm ? 'absolute' : 'absolute',
                        top: propsBuyTickets.sm ? '10px' : '0',
                        right: propsBuyTickets.sm ? '10px' : '0',
                        width: '40px',
                        height: '40px',
                        border: '1px solid #FFFFFF99',
                        margin: propsBuyTickets.sm ? '0px' : 'auto',
                        background: '#772784',
                    }}
                    onClick={() => propsBuyTickets.setShowDialog(false)}
                >
                    <Close
                        style={{
                            color: '#F0B12B',
                        }}
                    />
                </IconButton>

                {
                    propsBuyTickets.isWinner ?
                        <TaskAlt
                            style={{
                                color: '#21f800',
                                fontSize: '100px',
                            }}
                        />
                        :
                        <ReportProblem
                            style={{
                                color: '#f80800',
                                fontSize: '100px',
                            }}
                        />
                }

                <Typography
                    variant="h4"
                    style={{
                        color: '#FFFFFF',
                        margin: '0px',
                        fontWeight: 'bold',
                        letterSpacing: '1px',
                        marginTop: propsBuyTickets.sm ? '0px' : '12px',
                        marginBottom: propsBuyTickets.sm ? '0px' : '12px',
                        whiteSpace: 'pre-wrap',
                        textAlign: 'center',
                    }}
                >
                    {
                        propsBuyTickets.isWinner ?
                            'Congratulations, you won!'
                            :
                            'Sorry, you lost!\nTry again next time.'
                    }
                </Typography>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#F0B12B',
                            width: '120px',
                            color: '#772784',
                            fontFamily: '"Poppins", sans-serif',
                            fontSize: '18px',
                            fontWeight: '800',
                        }}
                        onClick={() => propsBuyTickets.setShowDialog(false)}
                    >
                        OK
                    </Button>
                </Box>
            </Box>
        </DialogCustom>
    )
}

export default DialogWinner;
