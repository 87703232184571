import React, {useEffect, useState} from "react";
import {Box, CardActionArea, Link, Paper, Typography, useMediaQuery} from "@mui/material";
import {OpenInNew} from "@mui/icons-material";
import DialogBuy from "../Earn/DialogBuy";

import BackgroundButton from "../../assets/imgs/button.png";
import BannerZoorbis from "../../assets/imgs/banner_buy.png";
import {useSelector} from "react-redux";

import {ethers} from "ethers";

import TokenAbi from "../../abis/Token.json";

const BuyToken = () => {

    const sm = useMediaQuery('(max-width: 899px)');

    // Redux
    const metamaskStore = useSelector(state => state.rootRdx.metamaskRdx);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [tokenBalance, setTokenBalance] = useState(0);
    const [loadingUserBalance, setLoadingUserBalance] = useState(false);
    const [showDialogBuy, setShowDialogBuy] = useState(false);

    useEffect(() => {
        if (metamaskStore.accountsState[0] && metamaskStore.avalancheConnectedState === true) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [metamaskStore.accountsState, metamaskStore.avalancheConnectedState]);

    const getUserTokenBalance = async () => {
        try {
            const tokenAbi = TokenAbi.abi;
            const tokenAddress = process.env.REACT_APP_ZORBI_ADDRESS;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

            const userAddress = metamaskStore.accountsState[0];
            return await tokenContract.balanceOf(userAddress);
        } catch (error) {
            console.error("Error getting user token balance:", error);
            throw error;
        }
    }

    const CallGetUserTokenBalance = async () => {
        try {
            const balance = await getUserTokenBalance();
            const formattedBalance = ethers.utils.formatEther(balance);
            setTokenBalance(Number(formattedBalance));
        } catch (error) {
            console.error("Error al obtener el saldo del token del usuario:", error);
            setTokenBalance(0);
        } finally {
            setLoadingUserBalance(false);
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            CallGetUserTokenBalance().then(() => {});
        }
    }, [isAuthenticated]);

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: sm ? 'calc(100% - 60px)' : 'calc(100% - 40px)',
                padding: '20px',
                margin: 'auto'
            }}
        >
            <DialogBuy
                sm={sm}
                showDialog={showDialogBuy}
                setShowDialog={setShowDialogBuy}
                isAuth={isAuthenticated}
            />

            {
                isAuthenticated ?
                    <Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 'calc(100% - 20px)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <h1
                                    style={{
                                        color: '#FFFFFFDD',
                                        fontWeight: '800',
                                        letterSpacing: '1px',
                                        fontSize: '40px',
                                        margin: '0px',
                                    }}
                                >
                                    Buy ZORBI
                                </h1>
                                <Link
                                    href="https://docs.zoorbis.com/"
                                    target="_blank"
                                    style={{
                                        marginLeft: '10px',
                                        marginTop: '17px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    sx={{
                                        color: '#F8C600 !important',
                                        '&:hover': {
                                            textDecoration: 'none',
                                            color: '#FFFFFFCC !important',
                                        }
                                    }}
                                >
                                    Docs <OpenInNew style={{fontSize: 15, marginLeft: '5px'}}/>
                                </Link>
                            </Box>
                        </Box>

                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 'calc(100% - 20px)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px',
                                padding: '10px',
                            }}
                        >
                            <CardActionArea
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    padding: '5px',
                                    borderRadius: '5px',
                                }}
                            >
                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#F0B12B',
                                        fontWeight: '500',
                                        letterSpacing: '1px',
                                        marginRight: '10px',
                                    }}
                                >
                                    My Balance:
                                </Typography>

                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#FFFFFFDD',
                                        fontWeight: '800',
                                        letterSpacing: '1px',
                                        marginRight: '5px',
                                    }}
                                >
                                    {
                                        loadingUserBalance ?
                                            'Loading...'
                                            :
                                            tokenBalance + ' ZORBI'
                                    }
                                </Typography>

                                {
                                    !loadingUserBalance &&
                                    <img
                                        src={require('../../assets/imgs/token-zoo.png')}
                                        alt="ZOO Token"
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            marginRight: '10px',
                                        }}
                                    />
                                }
                            </CardActionArea>
                        </Box>

                        <Paper
                            elevation={3}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: sm ? 'calc(100% - 20px)' : 'calc(100% - 40px)',
                                height: '500px',
                                padding: sm ? '10px' : '20px',
                                position: 'relative',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#37288A',
                                borderRadius: '20px',
                            }}
                        >
                            <img
                                src={BannerZoorbis}
                                alt="banner"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    zIndex: '0',
                                    padding: '10px',
                                    borderRadius: '20px',
                                }}
                            />

                            <CardActionArea
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: 'fit-content',
                                    padding: '20px',
                                    borderRadius: '5px',
                                    minWidth: '250px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    left: sm ? 'auto' : '10%',
                                }}
                                onClick={() => setShowDialogBuy(true)}
                            >
                                <img
                                    src={BackgroundButton}
                                    alt="background"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        zIndex: '0',
                                        padding: '10px',
                                    }}
                                />

                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#FFFFFF',
                                        fontWeight: '800',
                                        letterSpacing: '2px',
                                        fontSize: '2.0rem',
                                        zIndex: '1',
                                    }}
                                >
                                    BUY
                                </Typography>
                            </CardActionArea>
                        </Paper>
                    </Box>
                    :
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <h3
                            style={{
                                color: '#FFFFFFDD',
                                fontWeight: '500',
                            }}
                        >
                            Please connect your wallet to continue.
                        </h3>
                    </Box>
            }

        </Box>
    )
}

export default BuyToken;
