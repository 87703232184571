import {Button, styled} from "@mui/material";

const ButtonPlay = styled(Button)(() => ({
    width: '250px',
    height: '80px',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    color: '#fff',
    borderRadius: '40px',
}));

export default ButtonPlay;
