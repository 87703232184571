import React from "react";
import './App.css';
import {Container} from "@mui/material";
import Header from "./components/Header";
import TabsContainer from "./components/Tabs";

function App() {
    return (
        <Container
            maxWidth={'xl'}
            className="App"
            style={{
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'hidden',
                padding: '0',
                minHeight: '100vh',
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
            }}
        >
            <Header/>

            <TabsContainer/>
        </Container>
    );
}

export default App;
