import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Dialog, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import TextFieldCustom from "./TextFieldCustom";

import Swal from "sweetalert2";
import {ethers} from "ethers";

import ZorbiVendorABI from "../../abis/ZorbiVendor.json";

const DialogBuy = ({showDialog, setShowDialog, sm, isAuth}) => {

    const [amountToBuy, setAmountToBuy] = useState(1);
    const [tokenPrice, setTokenPrice] = useState(0);
    const [loading, setLoading] = useState(false);

    const getTokenPrice = () => {
        const tokenAbi = ZorbiVendorABI.abi;
        const tokenAddress = process.env.REACT_APP_ZORBI_VENDOR_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer, {
            gasLimit: 1000000,
        });

        const tx = tokenContract.getTotalCostZoorbis();

        tx.then((result) => {
            console.log('getTotalCostZoorbis: ', parseInt(result));
            setTokenPrice(parseInt(result));
        }).catch((error) => {
            console.log('getTotalCostZoorbis error: ', error);
            setTokenPrice(0);
        });
    }

    useEffect(() => {
        getTokenPrice();
    }, []);

    const buyToken = async () => {
        setLoading(true);

        const tokenAbi = ZorbiVendorABI.abi;
        const tokenAddress = process.env.REACT_APP_ZORBI_VENDOR_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const tx = tokenContract.buyZorbi(amountToBuy, {value: tokenPrice * amountToBuy});

        tx
            .then((result) => {
                result.wait().then((result2) => {
                    console.log('buyZorbi: ', result2);
                    setLoading(false);

                    if (result2.status === 1) {
                        Swal.fire({
                            title: "Success",
                            text: "Token acquisition complete with hash: " + result2['transactionHash'],
                            icon: "success",
                            confirmButtonText: "OK",
                        }).then((result) => {
                            setShowDialog(false);
                            // refresh site
                            window.location.reload();
                        });
                    }
                }).catch((error) => {
                    console.log('buyZorbi error: ', error);
                });
            })
            .catch(async (error) => {
                console.log('buyZorbi error: ', error);
                setLoading(false);
                //setShowDialog(false);

                await Swal.fire({
                    title: 'Error when trying to buy Zorbi',
                    text: error,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                });
            });
    }

    return (
        <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            fullScreen={sm}
            fullWidth={true}
            style={{
                overflow: 'hidden',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: 'calc(100% - 40px)',
                    height: sm ? '100%' : 'fit-content',
                    backgroundColor: '#1A0B3F',
                    padding: '20px',
                    overflow: 'hidden',
                }}
            >
                <IconButton
                    style={{
                        position: sm ? 'absolute' : 'absolute',
                        top: '20px',
                        right: '20px',
                        width: '40px',
                        height: '40px',
                        border: '1px solid #FFFFFF99',
                        margin: sm ? '0px' : 'auto',
                    }}
                    onClick={() => setShowDialog(false)}
                >
                    <Close
                        style={{
                            color: '#fff',
                        }}
                    />
                </IconButton>

                <Typography
                    variant="h5"
                    style={{
                        color: '#FFFFFF',
                        margin: '0px',
                        fontWeight: 'bold',
                        letterSpacing: '1px',
                        marginTop: sm ? '0px' : '30px',
                    }}
                >
                    Amount to buy
                </Typography>

                <TextFieldCustom
                    label="Amount"
                    value={amountToBuy}
                    onChange={(event) => setAmountToBuy(parseInt(event.target.value))}
                    type="number"
                />

                {
                    loading === true ?
                        <CircularProgress
                            style={{
                                color: '#F0B12B',
                                margin: '20px 0 0 auto',
                            }}
                        />
                        :
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'end',
                            }}
                        >
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#F0B12B',
                                    width: 'fit-content',
                                    margin: '20px 0 0 auto',
                                }}
                                onClick={() => buyToken()}
                            >
                                Buy Zorbis
                            </Button>

                            <Typography
                                variant="caption"
                                style={{
                                    color: '#FFFFFF',
                                    margin: '0px',
                                    fontWeight: '500',
                                    letterSpacing: '1px',
                                    marginTop: '10px',
                                    fontSize: '15px',
                                }}
                            >
                                {`Total: ${ethers.utils.formatEther(tokenPrice) * amountToBuy} MATIC`}
                            </Typography>
                        </Box>
                }
            </Box>
        </Dialog>
    )
}

export default DialogBuy;
