import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CardActionArea,
    CircularProgress,
    Dialog,
    IconButton,
    Link,
    Paper,
    Slider,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    CalendarTodayOutlined,
    Close,
    InfoOutlined,
    OpenInNew,
    RefreshOutlined,
    StartOutlined
} from "@mui/icons-material";
import TextFieldCustom from "./TextFieldCustom";
import DialogBuy from "./DialogBuy";
import moment from "moment";
import {useSelector} from "react-redux";
import TokenAbi from "../../abis/Token.json";
import {ethers} from "ethers";
import Swal from "sweetalert2";

import ZoorbisStakingABI from "../../abis/ZoorbisStakingV2.json";
import ZorbiTokenABI from "../../abis/ZORBI.json";

const Staking = () => {

    const sm = useMediaQuery('(max-width:899px)');

    // Redux
    const metamaskStore = useSelector(state => state.rootRdx.metamaskRdx);

    const [amountToStake, setAmountToStake] = useState(0);
    const [daysToStake, setDaysToStake] = useState(1);

    const [tokenBalance, setTokenBalance] = useState(0);

    const [showDialogDeposit, setShowDialogDeposit] = useState(false);
    const [showDialogBuy, setShowDialogBuy] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [isEnrolled, setIsEnrolled] = useState(false);
    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (metamaskStore.accountsState[0] && metamaskStore.avalancheConnectedState === true) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [metamaskStore.accountsState, metamaskStore.avalancheConnectedState]);

    const checkAllowance = async () => {
        const tokenAbi = ZorbiTokenABI.abi;
        const tokenAddress = process.env.REACT_APP_ZORBI_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const currentUser = signer.getAddress();

        return await tokenContract.allowance(currentUser, process.env.REACT_APP_ZOORBIS_STAKING_ADDRESS);
    }

    const stake = async () => {
        const tokenAbi = ZoorbisStakingABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_STAKING_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer, {
            gasLimit: 1000000,
        });

        const amount = ethers.utils.parseEther(amountToStake.toString());
        console.log('amount: ', amount.toString());

        const tx = await tokenContract.stakeToken(amount, daysToStake, {
            gasLimit: 1000000,
        });

        return new Promise((resolve, reject) => {
            tx.wait()
                .then((tx) => {
                    resolve(tx);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const withdraw = async () => {
        setLoading(true);

        try {

            const tokenAbi = ZoorbisStakingABI.abi;
            const tokenAddress = process.env.REACT_APP_ZOORBIS_STAKING_ADDRESS;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

            const tx = await tokenContract.claimReward();

            new Promise((resolve, reject) => {
                tx.wait()
                    .then(async (tx2) => {
                        if (tx2.status === 1) {
                            await Swal.fire({
                                title: "Success",
                                text: "Withdrawal complete with hash: " + tx2['transactionHash'],
                                icon: "success",
                                confirmButtonText: "OK",
                            });

                            setLoading(false);
                            // Refresh page
                            window.location.reload();
                        } else {
                            await Swal.fire({
                                title: "Info",
                                text: "The transaction with hash " + tx2.transactionHash + " has failed, please try again",
                                icon: "info",
                                confirmButtonText: "OK",
                            });

                            setLoading(false);
                        }

                        resolve(tx2);
                    })
                    .catch(async (error) => {
                        await Swal.fire({
                            title: "Error",
                            text: error.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });

                        setLoading(false);
                        reject(error);
                    });
            });
        } catch (e) {
            console.log('Error withdraw: ', e);
            let substringError = (e.data !== undefined && e.data !== null) ? e.data.message.substring(e.data.message.indexOf(':') + 1) : e.message;

            await Swal.fire({
                title: 'Error',
                text: substringError,
                icon: 'info',
                confirmButtonText: 'OK',
            });

            // await GetUserData();
            setLoading(false);
        }
    }

    const increaseAllowance = async () => {
        const tokenAbi = ZorbiTokenABI.abi;
        const tokenAddress = process.env.REACT_APP_ZORBI_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const currentUser = signer.getAddress();

        const tx = await tokenContract.increaseAllowance(process.env.REACT_APP_ZOORBIS_STAKING_ADDRESS, ethers.constants.MaxUint256, {from: currentUser});

        return new Promise((resolve, reject) => {
            tx.wait()
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const getUserTokenBalance = async () => {
        const tokenAbi = TokenAbi.abi;
        const tokenAddress = process.env.REACT_APP_ZORBI_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const userAddress = metamaskStore.accountsState[0];

        return await tokenContract.balanceOf(userAddress);
    }

    const CallGetUserTokenBalance = () => {
        getUserTokenBalance().then(balance => {
            const balanceTmp = ethers.utils.formatEther(balance);
            setTokenBalance(Number(balanceTmp));
        }).catch(e => {
            console.log(e);
            setTokenBalance(0);
        });
    }

    useEffect(() => {
        if (isAuthenticated) {
            CallGetUserTokenBalance();
        }
    }, [isAuthenticated]);

    const deposit = async () => {
        try {
            setLoading(true);

            let allowance = await checkAllowance();
            allowance = ethers.utils.formatEther(allowance);

            console.log(parseFloat(allowance));
            console.log(parseFloat(amountToStake.toString()));

            if (parseFloat(amountToStake.toString()) > parseFloat(allowance)) {
                let answer = await increaseAllowance();
                // console.log(answer);
                if (answer.status === 1) {
                    console.log("Allowance increased");
                    let answer2 = await stake();
                    if (answer2.status === 1) {
                        setShowDialogDeposit(false);
                        await Swal.fire({
                            title: "Success",
                            text: "Your deposit has been successfully processed",
                            icon: "success",
                            confirmButtonText: "OK",
                        });
                        await GetUserData();
                    } else {
                        await Swal.fire({
                            title: "Error",
                            text: "The transaction with hash " + answer2.transactionHash + " has failed",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    }
                }
            } else {
                let answer = await stake();
                console.log(answer);

                if (answer.status === 1) {
                    setShowDialogDeposit(false);
                    await Swal.fire({
                        title: "Success",
                        text: "Your deposit has been successfully processed",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                } else {
                    await Swal.fire({
                        title: "Info!",
                        text: "The transaction with hash " + answer.transactionHash + " has failed",
                        icon: "info",
                        confirmButtonText: "OK",
                    });
                }
            }

            await GetUserData();
            setLoading(false);
        } catch (e) {
            console.log(e);
            //let substringError = (e.data !== undefined && e.data !== null) ? e.data.message.substring(e.data.message.indexOf(':') + 1) : e.message;

            await Swal.fire({
                title: 'Error',
                text: e,
                icon: 'info',
                confirmButtonText: 'OK',
            });

            setLoading(false);
        }
    }

    const checkIfStaked = async () => {
        setUserData(null);

        const tokenAbi = ZoorbisStakingABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_STAKING_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        try {
            const tx = await tokenContract.isStaked();
            const result = tx;
            console.log("Result isStaked: ", result);

            setIsEnrolled(result);

            if (result) {
                await GetUserData();
            }
        } catch (e) {
            console.log('Error in checkIfStaked: ', e);
            setIsEnrolled(false);
        }
    }

    const GetUserData = async () => {
        try {
            await CallGetUserTokenBalance();

            const tokenAbi = ZoorbisStakingABI.abi;
            const tokenAddress = process.env.REACT_APP_ZOORBIS_STAKING_ADDRESS;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

            const tx = await tokenContract["getStakeInfo()"]();
            const result = await tx;
            console.log("Result getStakeInfo: ", result);

            const startTs = ethers.utils.formatUnits(result[0], 0);
            const endTs = ethers.utils.formatUnits(result[1], 0);
            const totalStaked = ethers.utils.formatEther(result[2]);

            const userData = {
                startTs: startTs,
                endTs: endTs,
                totalStaked: totalStaked,
            }

            setUserData(userData);

        } catch (e) {
            console.log(e);
            await Swal.fire({
                title: 'Error',
                text: e.data.message,
                icon: 'info',
                confirmButtonText: 'OK'
            });
        }

        //const txInt = Moralis.web3Library.utils.formatUnits(tx, '0');
        //const txDate = moment.unix(parseInt(txInt)).format('DD/MMM/YYYY HH:mm:ss');
        //setTokenExpiry(txDate);
    }

    useEffect(() => {
        checkIfStaked().then(() => {
        });
    }, []);

    const marksDays = [
        {
            value: 1,
            label: '1 Day',
        },
        {
            value: 7,
            label: '1 Week',
        },
        {
            value: 14,
            label: '2 Weeks',
        },
        {
            value: 90,
            label: '3 Months',
        },
        {
            value: 180,
            label: '6 Months',
        },
        {
            value: 365,
            label: '1 Year',
        }
    ];

    const marksDaysNoLabel = [
        {
            value: 1,
        },
        {
            value: 7,
        },
        {
            value: 14,
        },
        {
            value: 90,
        },
        {
            value: 180,
        },
        {
            value: 365,
        }
    ];

    const valueText = (value) => {
        // Find the mark with the given value.
        const mark = marksDays.find(mark => mark.value === value);
        // Use the mark's label or value.
        return mark ? mark.label : `${value} Days`;
    }

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: sm ? 'calc(100% - 60px)' : 'calc(100% - 40px)',
                padding: '20px',
                margin: 'auto'
            }}
        >
            <Dialog
                open={showDialogDeposit}
                onClose={() => setShowDialogDeposit(false)}
                fullScreen={sm}
                fullWidth={true}
                style={{
                    overflow: 'hidden',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: 'calc(100% - 40px)',
                        height: sm ? '100%' : 'fit-content',
                        backgroundColor: '#1A0B3F',
                        padding: '20px',
                        overflow: 'hidden',
                    }}
                >
                    <IconButton
                        style={{
                            position: sm ? 'absolute' : 'absolute',
                            top: '20px',
                            right: '20px',
                            width: '40px',
                            height: '40px',
                            border: '1px solid #FFFFFF99',
                            margin: sm ? '0px' : 'auto',
                        }}
                        onClick={() => setShowDialogDeposit(false)}
                    >
                        <Close
                            style={{
                                color: '#fff',
                            }}
                        />
                    </IconButton>

                    <Typography
                        variant="h5"
                        style={{
                            color: '#FFFFFF',
                            margin: '0px',
                            fontWeight: 'bold',
                            letterSpacing: '1px',
                            marginTop: sm ? '0px' : '30px',
                        }}
                    >
                        Deposit
                    </Typography>

                    <TextFieldCustom
                        label="Amount"
                        value={amountToStake}
                        onChange={(event) => setAmountToStake(parseInt(event.target.value))}
                        type="number"
                    />

                    <Typography
                        variant="h6"
                        style={{
                            color: '#FFFFFF',
                            margin: '0px',
                            fontWeight: 'bold',
                            letterSpacing: '1px',
                            marginTop: '30px',
                        }}
                    >
                        {`Time to stake: ${valueText(daysToStake)}`}
                    </Typography>

                    <Slider
                        value={daysToStake}
                        step={null}
                        marks={marksDaysNoLabel}
                        valueLabelDisplay={'auto'}
                        min={1}
                        max={365}
                        onChangeCommitted={(event, value) => {
                            setDaysToStake(value);
                        }}
                        sx={{
                            color: '#FFFFFF',
                        }}
                    />

                    {
                        loading === true ?
                            <CircularProgress
                                style={{
                                    color: '#F0B12B',
                                    margin: '20px 0 0 auto',
                                }}
                            />
                            :
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#F0B12B',
                                    width: 'fit-content',
                                    margin: '20px 0 0 auto',
                                }}
                                onClick={() => deposit()}
                            >
                                Deposit
                            </Button>
                    }
                </Box>
            </Dialog>

            <DialogBuy
                sm={sm}
                showDialog={showDialogBuy}
                setShowDialog={setShowDialogBuy}
                isAuth={isAuthenticated}
            />

            {
                isAuthenticated ?
                    <Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 'calc(100% - 20px)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <h1
                                    style={{
                                        color: '#FFFFFFDD',
                                        fontWeight: '800',
                                        letterSpacing: '1px',
                                        fontSize: '40px',
                                        margin: '0px',
                                    }}
                                >
                                    Earn
                                </h1>
                                <Link
                                    href="https://docs.zoorbis.com/"
                                    target="_blank"
                                    style={{
                                        marginLeft: '10px',
                                        marginTop: '17px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    sx={{
                                        color: '#F8C600 !important',
                                        '&:hover': {
                                            textDecoration: 'none',
                                            color: '#FFFFFFCC !important',
                                        }
                                    }}
                                >
                                    Docs <OpenInNew style={{fontSize: 15, marginLeft: '5px'}}/>
                                </Link>
                            </Box>

                            <CardActionArea
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    padding: '10px',
                                    position: 'relative',
                                }}
                                onClick={() => setShowDialogBuy(true)}
                            >
                                <img
                                    src={require('../../assets/imgs/button_2.png')}
                                    alt="ZOO Token"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                    }}
                                />

                                <img
                                    src={require('../../assets/imgs/token-zoo.png')}
                                    alt="ZOO Token"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '10px',
                                        zIndex: '1',
                                    }}
                                />
                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#F0B12B',
                                        fontWeight: '500',
                                        letterSpacing: '1px',
                                        zIndex: '2',
                                    }}
                                >
                                    Buy <span style={{fontWeight: '800'}}>ZORBI</span>
                                </Typography>
                            </CardActionArea>
                        </Box>

                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 'calc(100% - 20px)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px',
                                padding: '10px',
                            }}
                        >
                            <CardActionArea
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    padding: '5px',
                                    borderRadius: '5px',
                                }}
                            >
                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#F0B12B',
                                        fontWeight: '500',
                                        letterSpacing: '1px',
                                        marginRight: '10px',
                                    }}
                                >
                                    My Balance:
                                </Typography>

                                <Typography
                                    variant={'subtitle1'}
                                    style={{
                                        color: '#FFFFFFDD',
                                        fontWeight: '800',
                                        letterSpacing: '1px',
                                        marginRight: '5px',
                                    }}
                                >
                                    {tokenBalance}
                                </Typography>

                                <img
                                    src={require('../../assets/imgs/token-zoo.png')}
                                    alt="ZOO Token"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '10px',
                                    }}
                                />
                            </CardActionArea>
                        </Box>

                        <Paper
                            elevation={3}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: sm ? 'calc(100% - 20px)' : 'calc(100% - 40px)',
                                height: '500px',
                                padding: sm ? '10px' : '20px',
                                position: 'relative',
                                backgroundColor: '#37288A',
                                backgroundImage: 'url(' + require('../../assets/imgs/banner_earn.png') + ')',
                                backgroundSize: sm ? 'cover' : '100% 100%',
                            }}
                        >
                            <IconButton
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    border: '1px solid #ccc',
                                    position: 'absolute',
                                    top: '20px',
                                    right: '20px',
                                }}
                                onClick={async () => {
                                    await checkIfStaked();
                                }}
                            >
                                <RefreshOutlined
                                    style={{
                                        color: '#FFFFFFDD',
                                    }}
                                />
                            </IconButton>

                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant={'caption'}
                                    style={{
                                        fontSize: '16px',
                                        color: '#FFFFFFDD',
                                    }}
                                >
                                    Total Deposit
                                </Typography>
                                <Tooltip
                                    title={'Total amount of ZOO tokens you have deposited in the ZOORBIS Staking Pool.'}>
                                    <InfoOutlined style={{fontSize: '20px', marginLeft: '10px', color: '#FFFFFFDD'}}/>
                                </Tooltip>
                            </Box>

                            {
                                isEnrolled === false &&
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant={'caption'}
                                        style={{
                                            fontSize: '13px',
                                            color: '#F0B12B',
                                        }}
                                    >
                                        You have not enrolled in the ZOORBIS Staking Pool yet.
                                    </Typography>
                                </Box>
                            }

                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginTop: '20px',
                                }}
                            >
                                <Typography
                                    variant={'h4'}
                                    style={{
                                        fontWeight: '600',
                                        color: '#FFFFFFDD',
                                    }}
                                >
                                    {
                                        userData !== null ?
                                            parseFloat(userData.totalStaked).toFixed(1)
                                            :
                                            '0.00'
                                    }
                                </Typography>
                                <Typography
                                    variant={'h4'}
                                    style={{
                                        color: '#F0B12B',
                                        marginLeft: '10px',
                                        fontWeight: '800',
                                    }}
                                >
                                    ZORBI
                                </Typography>
                                <img
                                    src={require('../../assets/imgs/token-zoo.png')}
                                    alt="ZOO Token"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginLeft: '5px',
                                        marginBottom: '2px',
                                    }}
                                />
                            </Box>

                            {
                                isEnrolled === true &&
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant={'caption'}
                                        style={{
                                            fontSize: '15px',
                                            color: '#00c1ff',
                                        }}
                                    >
                                        Percentage yield: <span
                                        style={{fontWeight: 600}}>0.1% daily or 36.5% APY yearly</span>
                                    </Typography>
                                </Box>
                            }

                            {
                                userData !== null &&
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        marginBottom: '100px',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            height: 'fit-content',
                                        }}
                                    >
                                        <StartOutlined
                                            style={{
                                                fontSize: '20px',
                                                color: '#F0B12B',
                                                marginBottom: '2px',
                                                marginRight: '5px',
                                            }}
                                        />
                                        <Typography
                                            variant={'caption'}
                                            style={{
                                                color: '#FFFFFFDD',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Staking started at:
                                            </span>
                                            {
                                                ` ${moment.unix(userData.startTs).format('MMM/DD/YYYY HH:mm:ss')}`
                                            }
                                        </Typography>
                                    </Box>

                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            height: 'fit-content',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <CalendarTodayOutlined
                                            style={{
                                                fontSize: '20px',
                                                color: '#00d711',
                                                marginBottom: '2px',
                                                marginRight: '5px',
                                            }}
                                        />
                                        <Typography
                                            variant={'caption'}
                                            style={{
                                                color: '#FFFFFFDD',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Token available for withdrawal:
                                            </span>
                                            {
                                                ` ${moment.unix(userData.endTs).format('MMM/DD/YYYY HH:mm:ss')}`
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            }

                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: sm ? 'center' : 'flex-end',
                                }}
                            >
                                {
                                    (userData !== null && userData.endTs <= moment().unix()) === false && !isEnrolled ?
                                        <Button
                                            variant={'contained'}
                                            style={{
                                                backgroundColor: '#6D5EF7',
                                                height: '50px',
                                                width: '150px',
                                                borderRadius: '25px',
                                                position: 'relative',
                                            }}
                                            onClick={() => setShowDialogDeposit(true)}
                                        >
                                            <img
                                                src={require('../../assets/imgs/button.png')}
                                                alt="Deposit"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    position: 'absolute',
                                                    zIndex: '0',
                                                }}
                                            />

                                            <Typography
                                                variant={'caption'}
                                                style={{
                                                    color: '#FFFFFF',
                                                    position: 'relative',
                                                    zIndex: '1',
                                                    fontWeight: '800',
                                                    fontSize: '18px',
                                                }}
                                            >
                                                Deposit
                                            </Typography>
                                        </Button>
                                        : null
                                }

                                {
                                    (userData !== null && userData.endTs <= moment().unix()) ?
                                        loading === false ?
                                            <Button
                                                variant={'outlined'}
                                                style={{
                                                    borderColor: '#00d711',
                                                    height: '50px',
                                                    width: '150px',
                                                    color: '#00d711',
                                                    marginLeft: '20px',
                                                    borderRadius: '25px',
                                                }}
                                                onClick={() => withdraw()}
                                            >
                                                Withdraw
                                            </Button>
                                            :
                                            <CircularProgress
                                                style={{
                                                    color: '#00d711',
                                                }}
                                            />
                                        : null
                                }
                            </Box>
                        </Paper>
                    </Box>
                    :
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <h3
                            style={{
                                color: '#FFFFFFDD',
                                fontWeight: '500',
                            }}
                        >
                            Please connect your wallet to continue.
                        </h3>
                    </Box>
            }


        </Box>
    );
}

export default Staking;
