import {styled} from "@mui/material/styles";
import {TextField} from "@mui/material";

const TextFieldCustom = styled(TextField)(({theme}) => ({
    color: '#FFFFFF99',
    width: '100%',
    marginTop: '20px',
    '& .MuiOutlinedInput-root': {
        color: '#FFF',
        fontWeight: '500',
        fontSize: '18px',
        '& fieldset': {
            borderColor: '#FFFFFF99',
        },
        '&:hover fieldset': {
            borderColor: '#FFFFFF99',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F8C600',
        },
    },
    '& .MuiInputBase-input': {
        textAlign: 'center',
        fontSize: '18px',
    },
    '& label': {
        color: '#FFFFFF99',
    },
    '& label.Mui-focused': {
        color: '#F8C600',
    },
    // helperText
    '& .MuiFormHelperText-root': {
        color: '#F8C600',
    }
}));

export default TextFieldCustom;
