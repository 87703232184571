import React, {useState} from "react";
import {Box, Button, CircularProgress, Dialog, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import TextFieldCustom from "./../Earn/TextFieldCustom";

import Swal from "sweetalert2";
import ZorbiTokenABI from "../../abis/ZORBI.json";
import LottoABI from "../../abis/ZoorbisLotto.json";
import {ethers} from "ethers";

interface PropsBuyTickets {
    showDialog: boolean,
    setShowDialog: (value: boolean) => void,
    sm: boolean,
    isAuth: boolean,
    currentUser: any,
    getUserTickets: () => void,
}

const DialogBuyTickets: React.FC<PropsBuyTickets> = (propsBuyTickets: PropsBuyTickets) => {

    const [amountToBuy, setAmountToBuy] = useState(1);
    const [tokenPrice, setTokenPrice] = useState(0);
    const [loading, setLoading] = useState(false);

    const checkAllowance = async () => {
        const tokenAbi = ZorbiTokenABI.abi;
        const tokenAddress = process.env.REACT_APP_ZORBI_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const currentUser = signer.getAddress();

        return await tokenContract.allowance(currentUser, process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string);
    }

    const increaseAllowance = async () => {
        const tokenAbi = ZorbiTokenABI.abi;
        const tokenAddress = process.env.REACT_APP_ZORBI_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const currentUser = signer.getAddress();

        const tx = await tokenContract.increaseAllowance(process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string, ethers.constants.MaxUint256, {from: currentUser});

        return new Promise((resolve, reject) => {
            tx.wait()
                .then((result: any) => {
                    resolve(result);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    const executeBuyTickets = async () => {
        const tokenAbi = LottoABI.abi;
        const tokenAddress = process.env.REACT_APP_ZOORBIS_LOTTO_ADDRESS as string;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

        const currentUser = signer.getAddress();

        const tx = await tokenContract.buyTickets(amountToBuy, {
            from: currentUser,
            gasLimit: 1000000,
        });

        return new Promise((resolve, reject) => {
            tx.wait()
                .then(async (tx2: any) => {
                    if (tx2.status === 1) {
                        await Swal.fire({
                            title: "Success",
                            text: "Tickets acquisition complete with hash: " + tx2['transactionHash'],
                            icon: "success",
                            confirmButtonText: "OK",
                        });

                        await propsBuyTickets.getUserTickets();
                    } else {
                        await Swal.fire({
                            title: "Info",
                            text: "The transaction with hash " + tx2.transactionHash + " has failed, please try again",
                            icon: "info",
                            confirmButtonText: "OK",
                        });
                    }

                    propsBuyTickets.setShowDialog(false);
                    setLoading(false);
                    window.location.reload();

                    resolve(tx2);
                })
                .catch(async (error: any) => {
                    await Swal.fire({
                        title: "Info",
                        text: "The transaction with hash " + error.transactionHash + " has failed, please try again",
                        icon: "info",
                        confirmButtonText: "OK",
                    });

                    reject(error);
                });
        });
    }

    const buyToken = async () => {
        try {
            setLoading(true);

            let allowance = await checkAllowance();
            console.log('Current allowance 2: ', ethers.utils.formatEther(allowance));
            allowance = ethers.utils.formatEther(allowance);

            if (parseFloat(allowance) < 1.0) {
                console.log('Allowance < 1.0');
                let answer =  await increaseAllowance() as any;
                console.log('Answer increase allowance: ', answer);

                if (answer.status === 1) {
                    await executeBuyTickets();
                } else {
                    await Swal.fire({
                        title: "Error",
                        text: "The transaction with hash " + answer.transactionHash + " has failed",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }

            } else {
                await executeBuyTickets();
            }

        } catch (e: any) {
            console.log('Error buy tickets: ', e);
            let substringError = (e.data !== undefined && e.data !== null) ? e.data.message.substring(e.data.message.indexOf(':') + 1) : e.message;

            Swal.fire({
                title: 'Info ticket purchase',
                text: substringError,
                icon: 'info',
                confirmButtonText: 'OK',
            })
                .then(() => {
                    propsBuyTickets.setShowDialog(false);
                    setLoading(false);
                })
                .catch((error: any) => {
                    console.log('Error buy tickets: ', error);
                });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog
            open={propsBuyTickets.showDialog}
            onClose={() => propsBuyTickets.setShowDialog(false)}
            fullScreen={propsBuyTickets.sm}
            fullWidth
            style={{
                overflow: 'hidden',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: 'calc(100% - 40px)',
                    height: propsBuyTickets.sm ? '100%' : 'fit-content',
                    backgroundColor: '#1A0B3F',
                    padding: '20px',
                    overflow: 'hidden',
                }}
            >
                <IconButton
                    style={{
                        position: propsBuyTickets.sm ? 'absolute' : 'absolute',
                        top: '20px',
                        right: '20px',
                        width: '40px',
                        height: '40px',
                        border: '1px solid #FFFFFF99',
                        margin: propsBuyTickets.sm ? '0px' : 'auto',
                    }}
                    onClick={() => propsBuyTickets.setShowDialog(false)}
                >
                    <Close
                        style={{
                            color: '#fff',
                        }}
                    />
                </IconButton>

                <Typography
                    variant="h5"
                    style={{
                        color: '#FFFFFF',
                        margin: '0px',
                        fontWeight: 'bold',
                        letterSpacing: '1px',
                        marginTop: propsBuyTickets.sm ? '0px' : '30px',
                    }}
                >
                    Tickets to buy
                </Typography>

                <TextFieldCustom
                    label="Amount"
                    value={amountToBuy}
                    onChange={(event) => setAmountToBuy(parseInt(event.target.value))}
                    type="number"
                />

                {
                    loading ?
                        <CircularProgress
                            style={{
                                color: '#F0B12B',
                                margin: '20px 0 0 auto',
                            }}
                        />
                        :
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'end',
                            }}
                        >
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#F0B12B',
                                    width: 'fit-content',
                                    margin: '20px 0 0 auto',
                                }}
                                onClick={() => buyToken()}
                            >
                                Buy Tickets
                            </Button>
                        </Box>
                }
            </Box>
        </Dialog>
    )
}

export default DialogBuyTickets;
