import React, {useState} from "react";
import {Box, tabClasses, tabsClasses} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import {styled} from "@mui/material/styles";
import Staking from "../Earn";
import PlayComponent from "../Play";
import BuyToken from "../BuyToken";
import Exchange from "../Exchange";

const TabListCustom = styled(TabList)(({theme}) => ({
    backgroundColor: '#1E1460',
    marginTop: '30px',
    marginBottom: '20px',
    [`& .${tabsClasses.indicator}`]: {
        backgroundColor: '#FFFFFF',
        height: '3px',
    }
}));

const TabCustom = styled(Tab)(({theme}) => ({
    [`&.${tabClasses.root}`]: {
        color: '#fff',
        backgroundColor: '#FFFFFF00',
    },
    [`&.${tabClasses.selected}`]: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        backgroundColor: '#6D5EF7',
    },
    [`&.${tabClasses.labelIcon}`]: {
        color: 'red',
    }
}));

const TabsContainer = () => {

    const [tabValue, setTabValue] = useState('0');

    const handleTabValue = (event: any, newValue: React.SetStateAction<string>) => {
        setTabValue(newValue);
    }

    return(
        <Box
            style={{
                flex: 1,
            }}
        >
            <TabContext value={tabValue}>
                <TabListCustom
                    centered
                    onChange={handleTabValue}
                >
                    <TabCustom
                        label="Buy"
                        value={'0'}
                    />
                    <TabCustom
                        label="Earn"
                        value={'1'}
                    />
                    <TabCustom
                        label="Play Kadorbis"
                        value={'2'}
                    />
                    <TabCustom
                        label="Exchange"
                        value={'3'}
                    />
                </TabListCustom>

                <TabPanel
                    value={'0'}
                    style={{
                        padding: '0px',
                    }}
                >
                    <BuyToken />
                </TabPanel>

                <TabPanel
                    value={'1'}
                    style={{
                        padding: '0px',
                    }}
                >
                    <Staking />
                </TabPanel>

                <TabPanel
                    value={'2'}
                    style={{
                        padding: '0px',
                    }}
                >
                    <PlayComponent />
                </TabPanel>

                <TabPanel
                    value={'3'}
                    style={{
                        padding: '0px',
                    }}
                >
                    <Exchange />
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default TabsContainer;
