import React, {useState, useEffect} from "react";
import {
    Box,
    Button,
    CardActionArea,
    Container, Grid, IconButton,
    InputAdornment,
    Slider,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {initializeApp} from "firebase/app";
import {getFirestore, getDoc, doc, updateDoc} from "firebase/firestore";
import {getAnalytics, logEvent} from "firebase/analytics";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import Swal from "sweetalert2";
import {ethers} from "ethers";

import BackgroundLogin from "../../assets/imgs/background-exchange-login.png";
import LayerLogin from "../../assets/imgs/layer-exchange-login.png";
import BackgroundTextFieldLogin from "../../assets/imgs/background-textfield-exchange-login.png";

import BackgroundButton from "../../assets/imgs/button.png";
import BannerExchange from "../../assets/imgs/zoorbis_exchange_banner.png";
import TextFieldCustom from "../Earn/TextFieldCustom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

const TextFieldCustomLogin = styled(TextField)(({theme}) => ({
    color: '#FFFFFF99',
    width: '100%',
    marginTop: '20px',
    '& .MuiOutlinedInput-root': {
        color: '#FFF',
        fontWeight: '500',
        fontSize: '18px',
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F8C60077',
            borderRadius: '10px',
        },
    },
    '& .MuiInputBase-input': {
        textAlign: 'center',
        fontSize: '18px',
    },
    '& label': {
        color: '#FFFFFF99',
    },
    '& label.Mui-focused': {
        color: '#F8C600',
    },
    // helperText
    '& .MuiFormHelperText-root': {
        color: '#F8C600',
    }
}));

const Exchange = () => {
    const sm = useMediaQuery('(max-width: 899px)');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [user, setUser] = useState(null);

    const [coinsInGame, setCoinsInGame] = useState(0);
    const [valueSlider, setValueSlider] = useState(0);
    const [valueWallet, setValueWallet] = useState("");
    const [valueToken, setValueToken] = useState(0);

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "zoorbis-bubble.firebaseapp.com",
        projectId: "zoorbis-bubble",
        storageBucket: "zoorbis-bubble.appspot.com",
        messagingSenderId: "290351775197",
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

// Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const auth = getAuth(app);
    const signIn = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in
                logEvent(analytics, 'login', {
                    method: 'email',
                    uuid: userCredential.user.uid
                });

                const user = userCredential.user;
                setUser(user);

                await Swal.fire({
                    title: 'Success!',
                    text: "Welcome to Zoorbis Bubble Exchange " + user.email,
                    icon: 'success',
                    confirmButtonText: 'Cool'
                });
            })
            .catch(async (error) => {
                //const errorCode = error.code;
                //const errorMessage = error.message;
                setUser(null);

                await Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            });
    }

    const getDataFromFirestore = async () => {
        if (user) {
            // Get data from firestore
            const db = getFirestore(app);
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setCoinsInGame(docSnap.data()?.["coin_game"] || 0);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                setCoinsInGame(0);
            }
        }
    }

    useEffect(() => {
        getDataFromFirestore().then(() => {
        });
    }, [user]);

    useEffect(() => {
        const getWalletAddress = async () => {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const currentUser = await signer.getAddress();
                setValueWallet(currentUser);
                if (currentUser) {
                    await runApp(currentUser);
                }
            } catch (error) {
                console.log('Error al obtener la dirección de la billetera:', error);
            }
        };

        getWalletAddress().then(() => {
        });
    }, []);

    const runApp = async (walletParam = valueWallet) => {
        console.log("runApp", walletParam);
        setValueToken(0);
        fetch(process.env.REACT_APP_API_URL + "/getBalance", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                walletAddress: walletParam,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data);
                setValueToken(data?.[0]?.['balance'] ? data?.[0]?.['balance'] : 0);
            })
            .catch((error) => {
                console.log("error", error);
                setValueToken(0);
            });
    }

    useEffect(() => {
        runApp().then(() => {
        });
    }, [valueWallet]);

    const exchange = async () => {
        // 0x1CBd3b2770909D4e10f157cABC84C7264073C9Ec
        fetch(process.env.REACT_APP_API_URL + "/exchange", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                walletAddress: valueWallet,
                value: valueSlider,
                uid: user.uid,
            }),
        })
            .then((response) => response.json())
            .then(async (data) => {
                await Swal.fire({
                    title: 'Success!',
                    text: "Exchange success",
                    icon: 'success',
                    confirmButtonText: 'Cool'
                });

                const db = getFirestore(app);
                const docRef = doc(db, "users", user.uid);
                const docSnap = await updateDoc(docRef, {
                    coin_game: coinsInGame - valueSlider
                })


                await runApp();
                await getDataFromFirestore();
            })
            .catch((error) => {
                console.log("error", error);
                Swal.fire({
                    title: 'Error!',
                    text: "Exchange error",
                    icon: 'error',
                    confirmButtonText: 'Cool'
                });
            });
    }

    return (
        <Container
            maxWidth="lg"
        >
            {
                user ?
                    <Box
                        style={{
                            height: '100%',
                            minHeight: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#b8b8b8',
                        }}
                    >
                        <img
                            src={BannerExchange}
                            alt="Banner Exchange"
                            style={{
                                width: '100%',
                                maxWidth: '500px',
                                marginTop: 30,
                                marginBottom: 20,
                            }}
                        />

                        <Typography
                            variant="h5"
                            style={{
                                marginTop: 20,
                                marginBottom: 5,
                            }}
                        >
                            Welcome&nbsp;
                            <span style={{color: "#F0B12B", fontWeight: 'bold'}}>{user.email}</span>
                        </Typography>

                        <Typography variant="body">{user.uid}</Typography>

                        <Typography
                            variant="h6"
                            style={{
                                marginTop: 20,
                            }}
                        >
                            <span style={{color: "#F0B12B"}}>
                            Coins in game:&nbsp;
                            </span>
                            {coinsInGame}
                        </Typography>

                        <Typography
                            variant="h6"
                            style={{
                                marginTop: 20,
                                marginBottom: 10,
                                backgroundColor: '#E26D0E',
                                padding: 10,
                                color: '#FFF',
                            }}
                        >
                            1 ZORBI = 1000 ZOO (Game Coins)
                        </Typography>

                        <TextFieldCustom
                            label="Wallet address"
                            variant="outlined"
                            value={valueWallet}
                            helperText={'In this wallet we will send the ZOORBIS that you exchange, please make sure it is correct.'}
                            onChange={(e) => setValueWallet(e.target.value)}
                            style={{
                                marginTop: 20,
                            }}
                        />

                        <Typography
                            variant="h6"
                            style={{
                                marginTop: 20,
                            }}
                        >
                            ZORBIS in wallet: {ethers.utils.formatEther(valueToken)}
                        </Typography>

                        <Slider
                            value={valueSlider}
                            onChange={(event, newValue) => {
                                setValueSlider(newValue);
                            }}
                            aria-labelledby="continuous-slider"
                            min={0}
                            max={coinsInGame}
                            step={1}
                            style={{
                                maxWidth: 800,
                                marginTop: 20,
                            }}
                        />

                        <Typography
                            variant="caption"
                            style={{
                                marginTop: 0,
                                marginBottom: 50,
                            }}
                        >
                            {valueSlider}
                        </Typography>

                        <CardActionArea
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 'fit-content',
                                maxWidth: '300px',
                                padding: '20px',
                                borderRadius: '5px',
                                minWidth: '250px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '60px',
                            }}
                            onClick={async () => {
                                // Check if the wallet is correct with swal
                                await Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You will exchange " + valueSlider + " coins for " + valueSlider / 1000 + " ZORBIS" + " to the wallet " + valueWallet,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes, exchange it!'
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        console.log("valueSlider", valueSlider);
                                        await exchange();
                                    }
                                }).catch((error) => {
                                    console.log("error", error);
                                });
                            }}
                        >
                            <img
                                src={BackgroundButton}
                                alt="background"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    zIndex: '0',
                                    padding: '10px',
                                }}
                            />

                            <Typography
                                variant={'subtitle1'}
                                style={{
                                    color: '#FFFFFF',
                                    fontWeight: '800',
                                    letterSpacing: '2px',
                                    fontSize: '2.0rem',
                                    zIndex: '1',
                                }}
                            >
                                Exchange
                            </Typography>
                        </CardActionArea>

                    </Box>
                    :
                    <Box
                        style={{
                            height: '100%',
                            minHeight: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Grid
                            container
                            style={{
                                marginTop: 50,
                            }}
                        >
                            <Grid
                                item xs={12} sm={12} md={8}
                                style={{
                                    position: 'relative',
                                    display: sm ? 'flex' : 'none',
                                    marginBottom: 30,
                                }}
                            >
                                <img
                                    src={LayerLogin}
                                    alt={'Layer Login'}
                                    style={{
                                        width: 'auto',
                                        height: '70%',
                                        position: 'absolute',
                                        left: 'auto',
                                        right: 0,
                                        bottom: 0,
                                        objectFit: 'contain',
                                    }}
                                />

                                <img
                                    src={BackgroundLogin}
                                    alt={'Layer Login'}
                                    style={{
                                        width: '100%',
                                        height: '300px',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12} sm={12} md={4}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 10,
                                    paddingRight: 20,
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{
                                        marginBottom: 8,
                                        color: '#F8C600',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Sign In
                                </Typography>

                                <Typography
                                    variant="body"
                                    style={{
                                        marginBottom: 20,
                                        color: '#FFFFFF99',
                                        textAlign: 'center',
                                    }}
                                >
                                    Please sign in with the email and password you used to create your account in the Zoorbis
                                    Bubbles game app.
                                </Typography>

                                <TextFieldCustomLogin
                                    label="Email"
                                    variant="outlined"
                                    size={"medium"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                        width: '100%',
                                        maxWidth: 350,
                                        border: 'none',
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundImage: `url(${BackgroundTextFieldLogin})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            border: 'none'
                                        }
                                    }}
                                />

                                <TextFieldCustomLogin
                                    label="Password"
                                    variant="outlined"
                                    size={"medium"}
                                    value={password}
                                    inputProps={{
                                        type: showPassword ? 'text' : 'password',
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundImage: `url(${BackgroundTextFieldLogin})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    {showPassword ? <VisibilityOff style={{color: 'white'}}/> :
                                                        <Visibility style={{color: 'white'}}/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{
                                        marginBottom: 50,
                                        width: '100%',
                                        maxWidth: 350,
                                    }}
                                />

                                <CardActionArea
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'fit-content',
                                        maxWidth: '300px',
                                        padding: '20px',
                                        borderRadius: '5px',
                                        minWidth: '250px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '60px',
                                    }}
                                    onClick={signIn}
                                >
                                    <img
                                        src={BackgroundButton}
                                        alt="background"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            zIndex: '0',
                                            padding: '10px',
                                        }}
                                    />

                                    <Typography
                                        variant={'subtitle1'}
                                        style={{
                                            color: '#FFFFFF',
                                            fontWeight: '800',
                                            letterSpacing: '2px',
                                            fontSize: '2.0rem',
                                            zIndex: '1',
                                        }}
                                    >
                                        Sign In
                                    </Typography>
                                </CardActionArea>
                            </Grid>

                            <Grid
                                item xs={12} sm={12} md={8}
                                style={{
                                    position: 'relative',
                                    display: sm ? 'none' : 'flex',
                                }}
                            >
                                <img
                                    src={LayerLogin}
                                    alt={'Layer Login'}
                                    style={{
                                        width: 'auto',
                                        height: '70%',
                                        position: 'absolute',
                                        left: 'auto',
                                        right: 0,
                                        bottom: 0,
                                        objectFit: 'contain',
                                    }}
                                />

                                <img
                                    src={BackgroundLogin}
                                    alt={'Layer Login'}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        minHeight: '500px',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
            }
        </Container>
    );
}

export default Exchange;
